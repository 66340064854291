<button
  (click)="action.emit()"
  [disabled]="disabled"
  [class.log-in-button]="textType === 'log-in'"
  [ngClass]="{'blue-filled': type === 'blue-filled', 'blue': type === 'blue', 'light-filled': type === 'light-filled',
              'light': type === 'light', 'disabled': type === 'disabled'}"
  class="d-flex justify-content-center align-items-center"
>
  <span *ngIf="!loading" [ngClass]="{'log-in-text': textType === 'log-in', 'normal-text': textType === 'normal', 'big-text': textType === 'big'}">{{label}}</span>
  <mat-spinner *ngIf="loading"></mat-spinner>
</button>
