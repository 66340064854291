import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {CandidateModel} from "../models/candidate.model";
import {environment} from "../../environments/environment";
import {CandidateTypes} from "../types";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  private readonly _apiUrl = environment.apiUrl + 'api/';
  private _senateCandidates?: CandidateModel[];
  private _councilCandidates?: CandidateModel[];

  constructor(
    private readonly _http: HttpClient
  ) { }

  get senateCandidates$(): Observable<CandidateModel[]> {
    if (this._senateCandidates) return of(this._senateCandidates);

    return this._getCandidates(CandidateTypes.SENATE).pipe(tap(candidates => (this._senateCandidates = candidates)))
  }

  get councilCandidates$(): Observable<CandidateModel[]> {
    if (this._councilCandidates) return of(this._councilCandidates);

    return this._getCandidates(CandidateTypes.COUNCIL).pipe(tap(candidates => (this._councilCandidates = candidates)))
  }

  private _getCandidates(type: CandidateTypes): Observable<CandidateModel[]> {
    const url = `${this._apiUrl}candidates/type?type=${type}`;

    return this._http.get<CandidateModel[]>(url);
  }

  getById(id: string): Observable<CandidateModel> {
    const url = `${this._apiUrl}candidates/${id}`;

    return this._http.get<CandidateModel>(url);
  }
}
