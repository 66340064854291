import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from './button/button.component';
import {MatIconModule} from '@angular/material/icon';
import {MenuComponent} from './menu/menu.component';
import {RouterModule} from "@angular/router";
import { MobileMenuCloserComponent } from './menu/mobile-menu-closer/mobile-menu-closer.component';
import { SelectComponent } from './select/select.component';
import {FormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    ButtonComponent,
    MenuComponent,
    MobileMenuCloserComponent,
    SelectComponent
  ],
  exports: [
    ButtonComponent,
    MenuComponent,
    SelectComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        FormsModule,
        MatProgressSpinnerModule,
    ]
})
export class SharedModule {
}
