<ng-container *ngIf="candidate">
  <div class="candidate-page-container flex-column w-100 d-flex align-items-center justify-content-center">
    <div class="candidate-info-container d-flex align-items-center justify-content-center">
      <div [style.background-image]="'url(\'' + candidate.photo.path + '\')'" class="candidate-image"></div>
      <div class="candidate-inner-container w-100 h-100 flex-column">
        <div class="candidate-text-container">
          <p>{{candidate.firstName + ' ' + candidate.lastName.toUpperCase()}}</p>
          <p>{{candidate.candidateType === 1 ? 'Candidat Senatul Univerității' : 'Candidat Consiliul Facultății'}}</p>
          <p [class.longParagraph]="candidate.description.length > 2500" class="paragraph" *ngFor="let paragraph of paragraphs">{{paragraph}}</p>
        </div>
        <div class="cv-letter-buttons d-flex flex-row justify-content-center align-items-center">
          <a [href]="candidate.cv.path" target="_blank">
            <app-button label="CV" type="blue-filled" textType="normal"></app-button>
          </a>
          <a [href]="candidate.letter.path" target="_blank">
            <app-button label="Scrisoare de intenție" type="blue-filled" textType="normal"></app-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!candidate">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>

