import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  selectedValue = 'Senatul Universității';
  @Output() readonly changeEmitter = new EventEmitter<1 | 2>();

  handleChange(): void {
    if (this.selectedValue === 'Senatul Universității') {
      this.changeEmitter.emit(1);
    } else {
      this.changeEmitter.emit(2);
    }
  }
}
