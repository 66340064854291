import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {
  }

  canActivate(): boolean {
    if (!this._authService.isTokenValid()) {
      this._router.navigate(['login']).then();
      return false;
    }
    return true;
  }

}
