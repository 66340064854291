<div class="menu-container">
  <div class="square" *ngIf="screenWidth < 925"></div>

  <div class="voteasy-image">
    <img [routerLink]="''" src="./assets/images/VOTeasy.png" alt="logo">
  </div>

  <img class="menu-icon" id="menu-icon" src="./assets/images/menu-icon.png" (click)="openMenu(true)" alt="icon"
       *ngIf="screenWidth < 925">

  <app-mobile-menu-closer (click)="openMenu(false)" [hidden]="showMenu()" *ngIf="screenWidth < 925"></app-mobile-menu-closer>

  <div class="text-center" [hidden]="showMenu()">
    <div class="menu-text-mobile" [hidden]="showMenu()" *ngIf="screenWidth < 925">MENIU</div>
    <div *ngFor="let item of menuItems,let i = index" class="menu-items">
      <div *ngIf="item === 'Regulament' || item === 'Cum votez?' || item === 'Candidați'; else pageItems">
        <div *ngIf="item === 'Regulament'" class="text">
          <a target="_blank"
             href="https://cospol.upt.ro/wp-content/uploads/2021/11/HS_05_02.11.2021_Regulament-alegeri-structuri-conducere-studenti.pdf?fbclid=IwAR1X0wMqKaIeUV2HIHqjzNl03CF2zuoUUopkjwzGFyFMdYdonMwXmjrYoW4"
          >
            {{item}}
          </a>
        </div>
        <div *ngIf="item === 'Cum votez?'" class="text">
          <a target="_blank"
             href="https://vote.ligaac.ro/upload/Ghid%20Aplicat%CC%A6ie%20vot%20-2023_9265134464.pdf"
          >
             {{item}}
          </a>
        </div>
        <div *ngIf="item === 'Candidați'" class="text">
          <a target="_blank"
             href="https://drive.google.com/drive/folders/1-nttnnUdHnI2g_ZS6dVKHV4emMi1M9N6?usp=drive_link"
          >
            {{item}}
          </a>
        </div>
      </div>
      <ng-template #pageItems >
        <div [routerLink]="menuLinks[i]" class="text" [routerLinkActive]="'hov'"
             (click)="openMenu(false)">{{item}}</div>
      </ng-template>
    </div>
  </div>

  <div [routerLink]="'login'" [hidden]="showMenu()" class="log-out-button" (click)="openMenu(false); logout()">
    <div class="text">
      Log out
    </div>
  </div>
</div>
