import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {LoginRequestModel} from "../models/user.model";
import {SessionService} from "./session.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {SessionModel} from '../models/session.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _apiUrl = environment.apiUrl + 'api/';

  constructor(
    private readonly _http: HttpClient,
    private readonly _router: Router,
    private readonly _sessionService: SessionService
  ) {
  }

  login(request: LoginRequestModel): Observable<SessionModel> {
    const url = `${this._apiUrl}auth/login?studentCode=${request.studentCode}&token=${request.token}`;
    return this._http.post<SessionModel>(url, {});
  }

  logout(): void {
    console.log("here");
    this._sessionService.removeSession();
    console.log("here2");
    this._router.navigate(['login']).then();
  }

  isTokenValid(): boolean {
    return this.getDateFromToken() > new Date();
  }

  getDateFromToken(): Date {
    const token = this._sessionService.token
    if (token !== null) {
      return new Date(token.exp * 1000);
    }
    return new Date();
  }

  getHeaders(needsAuth?: boolean): HttpHeaders {
    if (!needsAuth){
      return new HttpHeaders();
    }
    if (this._sessionService.token === null) {
      return new HttpHeaders();
    }

    return new HttpHeaders().append('Authorization', `Bearer ${this._sessionService.session?.token}`);
  }

  getOptions(needsAuth?: boolean): {headers?: HttpHeaders} {
    return {headers: this.getHeaders(needsAuth)}
  }
}

