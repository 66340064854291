import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./guards/auth.guard";
import {CandidateInfoPageComponent} from "./pages/candidate-info-page/candidate-info-page.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'vote'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'results',
    loadChildren: () => import('./pages/results-page/results-page.module').then(m => m.ResultsPageModule)
  },
  /*{
    path: 'candidates',
    loadChildren: () => import('./pages/student-presentation-page/student-presentation-page.module').then(m => m.StudentPresentationPageModule),
  },*/
  /*{
    path: 'candidate/:id',
    component: CandidateInfoPageComponent
  },*/
  {
    path: 'vote',
    loadChildren: () => import('./pages/vote-page/vote-page.module').then(m => m.VotePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'callback',
    loadChildren: () => import('./pages/confirm-token-page/confirm-token-page.module').then(m => m.ConfirmTokenPageModule)
  },
  {path: '**', redirectTo: 'vote', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
