import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent {
  screenWidth: number;
  hideMenu = true;
  menuItems: string[] = [
    'Portal Vot',
    //'Candidați',
    'Statistici',
    //'Cum votez?',
    'Candidați',
    'Regulament'
  ];
  menuLinks: string[] = [
    'vote',
    //'candidates',
    'results',
  ]

  constructor(
    private readonly _authService: AuthService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
  }

  logout(): void {
    this._authService.logout();
  }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
  }

  showMenu(): boolean {
    return this.hideMenu && this.screenWidth < 925;
  }

  openMenu(appearMenu: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 925) {
        if (appearMenu) {
          this.hideMenu = false;
          this.document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
        } else {
          this.hideMenu = true;
          this.document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
        }
      }
    }

  }
}





