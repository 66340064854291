import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {TokenModel} from "../models/token.model";
import {SessionModel} from '../models/session.model';
import {isPlatformBrowser} from '@angular/common';

const ACCESS_TOKEN = 'voteasy_access_token';
const APP_SESSION = 'voteasy_session';

const decode = (token: string | null): TokenModel | null => {
  if (!token) return null;
  return JSON.parse(atob(token.split('.')[1])) as TokenModel;
};

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  get session(): SessionModel | null {
    if (!isPlatformBrowser(this.platformId)) return null;
    return JSON.parse(sessionStorage.getItem(APP_SESSION)!);
  }
  set session(session: SessionModel | null) {
    if (session === null) return;
    if (!isPlatformBrowser(this.platformId)) return;
    sessionStorage.setItem(APP_SESSION, JSON.stringify(session));
  }

  get token(): TokenModel | null {
    return decode(this.session?.token!);
  }

  removeSession(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    console.log('here3');
    sessionStorage.removeItem(APP_SESSION);
  }
}
