import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CandidateModel} from "../../models/candidate.model";
import {CandidateService} from "../../services/candidate.service";

@Component({
  selector: 'app-candidate-info-page',
  templateUrl: './candidate-info-page.component.html',
  styleUrls: ['./candidate-info-page.component.scss']
})
export class CandidateInfoPageComponent implements OnInit {
  candidate: CandidateModel;
  paragraphs: string[] = [];

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _candidateService: CandidateService
  ) {
  }

  ngOnInit(): void {
    this._candidateService.getById(this._route.snapshot.paramMap.get('id')!).subscribe(candidate => {
      this.candidate = candidate;
      this.getParagraphs(candidate.description);
    });
  }

  getParagraphs(letter: string) {
    let current = '';
    letter.split('\n').forEach(newline => {
      if (newline.length !== 1) current = current + ' ' + newline;
      else {
        this.paragraphs.push(current);
        current = '';
      }
    });
    this.paragraphs.push(current);
  }

}
