import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private readonly _authService: AuthService,
  ) { }

  showMenu(): boolean {
    return this._authService.isTokenValid();
  }
}
