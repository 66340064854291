import {Component} from '@angular/core';
import {AuthService} from "./services/auth.service";
import {MenuService} from "./services/menu.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    readonly menuService: MenuService
  ) {
  }
}

