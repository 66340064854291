<div class="select">
  <select class="form-select" [(ngModel)]="selectedValue" (ngModelChange)="handleChange()">
    <option>
      Consiliul Facultății
    </option>
    <option>
      Senatul Universității
    </option>
  </select>
</div>
