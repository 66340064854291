import {APP_ID, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from "@angular/common/http";
import {SharedModule} from "./shared/shared.module";
import { CandidateInfoPageComponent } from './pages/candidate-info-page/candidate-info-page.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    CandidateInfoPageComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        HammerModule,
        MatProgressSpinnerModule
    ],
  providers: [
    {provide: APP_ID, useValue: 'voteasy-frontend'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
